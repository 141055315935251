// import ingonyamaLogo from "../assets/companies/logos/ingonyama.svg";
// import scrollLogo from "../assets/companies/logos/scroll.svg";
// import risczeroLogo from "../assets/companies/logos/risczero.svg";
// import stealthcoLogo from "../assets/companies/logos/stealthco.svg";

// import ingonyamaBackground from "../assets/companies/backgrounds/ingonyama.png";
// import scrollBackground from "../assets/companies/backgrounds/scroll.png";
// import risczeroBackground from "../assets/companies/backgrounds/risczero.png";
// import stealthcoBackground from "../assets/companies/backgrounds/stealthco.png";


const data = {
  "home": {
    "metaTitle": "Geometry",
    "metaDescription": "High impact deep tech investing",
    "metaKeywords": "Geometry, Deep Tech, Investor, Applied Cryptography, Zero Knowledge Proofs, Semiconductor, Materials Science, Computer Technologies"
  },
  "about": {
    "textOne": process.env.REACT_APP_ABOUT_TEXT_ONE || "Placeholder",
    "textTwo": process.env.REACT_APP_ABOUT_TEXT_TWO || "Placeholder",
    "metaTitle": "Geometry",
    "metaDescription": "High impact deep tech investing",
    "metaKeywords": "Geometry, Deep Tech, Investor, Applied Cryptography, Zero Knowledge Proofs, Semiconductor, Materials Science, Computer Technologies"
  },
  "style": {
    "gradient1": "rgba(35,40,160,0.9)",
    "gradient2": "rgba(100,190,240,0.9)"
  },
  "background": "https://images.unsplash.com/photo-1634041659579-2edab82c8f1d?q=80&w=2063&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "backgroundInner": "https://images.unsplash.com/photo-1634041659579-2edab82c8f1d?q=80&w=2063&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "people": [
    {
      "name": "Ben",
      "role": "General Partner",
      "bio": "Ben was a tech cofounder, computational biophysics and machine learning researcher, and trader. His interests lie in financial markets and economics, deep tech, and dead languages like Ancient Greek and PIE. Studied at Harvard College.",
      "twitterUrl": "https://twitter.com/benlevy0",
      "linkedinUrl": "https://www.linkedin.com/in/ben-levy365/",
      "imageUrl": "https://pbs.twimg.com/profile_images/1580605451598716928/lj6rJXf6_400x400.jpg"
    },
    {
      "name": "Tom",
      "role": "General Partner & Founder",
      "bio": "Tom was cofounder and CEO at Aztec Network, the Ethereum Layer 2. He holds a BA & MMath (Part III) from Cambridge. Armchair reader of cryptography, writer of cryptography primers, with interests in financial economics. Outside Web3, Tom enjoys piano and singing.",
      "twitterUrl": "https://twitter.com/tomwalpo",
      "linkedinUrl": "https://www.linkedin.com/in/thomaspocock/",
      "imageUrl": "https://pbs.twimg.com/profile_images/1393845299542695938/_ZaSAwcT_400x400.jpg"
    }
  ],
  "socials": {
      "twitterUrl": "https://twitter.com/__geometry__",
      "linkedinUrl": "https://www.linkedin.com/company/80745324/"
  }
}

export default data;