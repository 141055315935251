import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import Lottie from "lottie-react";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up.json";
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down.json";

export default function WritingPage({
  callback,
  callback2,
  metaTitle,
  metaDescription,
  metaKeywords,
}) {
  const [posts, setPosts] = useState([]);
  const hexagonDown = useRef();
  const hexagonUp = useRef();
  const SUBSTACK_URL = process.env.REACT_APP_WRITING_URL;
  const SUBSTACK_TITLE = process.env.REACT_APP_WRITING_TITLE || "Latest posts from our Substack";

  useEffect(() => {
    callback(false);
    callback2(false);
    hexagonDown.current.playSegments([0, 50], true);
    hexagonUp.current.playSegments([0, 50], true);

    // Use a CORS proxy to fetch the RSS feed
    const CORS_PROXY = "https://api.allorigins.win/raw?url=";
    const SUBSTACK_FEED = SUBSTACK_URL + "feed";
    
    fetch(`${CORS_PROXY}${encodeURIComponent(SUBSTACK_FEED)}`)
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        const items = data.querySelectorAll('item');
        const feedPosts = [...items].map(item => ({
          title: item.querySelector('title')?.textContent,
          link: item.querySelector('link')?.textContent,
          description: item.querySelector('description')?.textContent,
          pubDate: new Date(item.querySelector('pubDate')?.textContent),
        }));
        setPosts(feedPosts);
      })
      .catch(error => console.error('Error fetching Substack feed:', error));

    return () => {
      // Cleanup not needed for fetch
    };
  }, [callback, callback2]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title data-rh="true">{metaTitle || "Writing | Geometry"}</title>
        <meta
          name="description"
          content={truncateString(
            metaDescription || "Geometry's writing and insights",
            152
          )}
          data-rh="true"
        />
        <meta name="keywords" content={metaKeywords} data-rh="true" />
      </Helmet>
      <div className="fixed left-0 top-0 z-0 h-screen w-screen">
        <Lottie
          lottieRef={hexagonDown}
          className="absolute scale-50 lg:scale-100"
          style={{ left: "-300px", width: "600px", top: "-200px" }}
          animationData={hexagonDownAnimation}
          autoplay={false}
          loop={false}
        />
        <Lottie
          lottieRef={hexagonUp}
          className="absolute scale-50 lg:scale-100"
          style={{ right: "-300px", width: "600px", bottom: "-300px" }}
          animationData={hexagonUpAnimation}
          autoplay={false}
          loop={false}
        />
      </div>
      <div className="fade-in absolute flex h-full w-full flex-col">
        <div className="z-20 flex grow flex-col items-center justify-center">
          <div className="w-full max-w-4xl px-4 space-y-4">
            <div className="text-center mb-8">
              <h1 className="font-clarikageo text-2xl font-medium text-white mb-2">
                {SUBSTACK_TITLE.split(/\b(Substack)\b/).map((part, index) => 
                  part === 'Substack' ? (
                    <a 
                      key={index}
                      href={SUBSTACK_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-bold text-white underline hover:opacity-80"
                    >
                      {part}
                    </a>
                  ) : part
                )}
              </h1>
            </div>
            {posts.map((post, index) => (
              <a 
                key={index} 
                href={post.link}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-6 bg-transparent border-2 border-white/30 rounded-lg hover:bg-white/5 hover:border-white/50 transition-all"
              >
                <h2 className="font-clarikageo text-xl font-medium text-white mb-2">{post.title}</h2>
                <p className="font-clarikageo text-lg font-light text-white/80">{post.description}</p>
                <p className="font-clarikageo text-sm font-light text-white/60 mt-2">
                  {post.pubDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}