import React, {useRef, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Lottie from "lottie-react";
import linesAnimation from "../animations/home-page-lines-layer.json";
import revolverAnimation from "../animations/menu-selection-revolver-standard.json";

export default function Menu({navItems, callback}) {
    let index = 0;
    const revolver = useRef();
    const [expandedMenuIndex, setExpandedMenuIndex] = useState(-1);

    const Rotate = function () {

        index++;
        if (index > 5) index = 0;

        if (index === 1) revolver.current.playSegments([0, 31], true);
        else if (index === 2) revolver.current.playSegments([31, 61], true);
        else if (index === 3) revolver.current.playSegments([61, 91], true);
        else if (index === 4) revolver.current.playSegments([91, 121], true);
        else if (index === 5) revolver.current.playSegments([121, 151], true);
        else if (index === 6) revolver.current.playSegments([151, 181], true);
        else if (index === 0) revolver.current.playSegments([181, 211], true);
    }

    useEffect(() => {
        setExpandedMenuIndex(-1);
    }, [navItems])
    

    return (
        <div className="relative w-full h-full overflow-visible">
            <div className="bg-gradient fixed inset-0"></div>
            <div className="hidden absolute left-0 w-full top-0 h-full lg:flex justify-center items-center">
                <Lottie
                    className="h-full w-auto scale-[2] opacity-25"
                    animationData={linesAnimation}
                    autoplay={true}
                    loop={true}
                />
            </div>
            <div className="hidden absolute left-0 w-full top-0 h-full lg:flex justify-center items-center">
                <Lottie
                    lottieRef={revolver}
                    className="h-full w-auto scale-[1.1]"
                    animationData={revolverAnimation}
                    autoplay={false}
                    loop={false}
                />
            </div>
            <div
                className="absolute left-0 w-full top-0 h-full -top-10 lg:top-auto flex flex-row justify-center items-center">
                <div className="relative">
                    {<div className={`flex flex-col space-y-6 lg:space-y-9 transition-all duration-500 ${expandedMenuIndex < 0 ? 'opacity-100 translate-x-0 pointer-events-auto' : 'opacity-0 -translate-x-full pointer-events-none'}`}>
                        <Link className="group flex flex-row space-x-8 items-center" to="/" onClick={callback}
                            onMouseEnter={Rotate}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="flex-none opacity-20 group-hover:opacity-100 transition-all duration-500"
                                width="45.123" height="52.105" viewBox="0 0 45.123 52.105">
                                <path id="Subtraction_1" data-name="Subtraction 1"
                                    d="M-3074.438-1188.9h0L-3097-1201.922v-26.052l22.562-13.026,22.561,13.026v26.052l-22.561,13.027Zm.075-40.026a2.018,2.018,0,0,0-1.352.519l-10.779,9.7v10.514a3.239,3.239,0,0,0,3.235,3.235h17.792a3.239,3.239,0,0,0,3.235-3.235V-1218.7l-10.779-9.7A2.018,2.018,0,0,0-3074.364-1228.921Zm-5.055,21.945h-3.841a1.214,1.214,0,0,1-1.213-1.213v-9.611l10.11-9.1,10.109,9.1v9.611a1.214,1.214,0,0,1-1.213,1.213h-3.841v-5.081a5.06,5.06,0,0,0-5.054-5.054,5.061,5.061,0,0,0-5.055,5.054v5.081Zm8.088,0h-6.066v-5.081a3.036,3.036,0,0,1,3.033-3.032,3.036,3.036,0,0,1,3.033,3.032v5.08Z"
                                    transform="translate(3097 1241)" fill="#d2f9f8"/>
                            </svg>
                            <span
                                className="flex-1 font-clarikageo text-white text-xl font-semibold tracking-[13.2px] uppercase opacity-60 group-hover:opacity-100 text-left transition-all duration-500">Home</span>
                        </Link>

                        {
                            navItems.map((navItem, itemIndex) => (
                                <div key={itemIndex} className='flex gap-1 align-middle items-center'>
                                    {!navItem.subMenu && <Link className="group flex flex-row space-x-8 items-center" to={navItem.url} onClick={callback} onMouseEnter={Rotate}>
                                            {navItem.icon()}
                                        <span className="flex-1 font-clarikageo text-white text-xl font-semibold tracking-[13.2px] uppercase opacity-60 group-hover:opacity-100 text-left">
                                            {navItem.name}
                                        </span>
                                    </Link>}
                                    
                                    {
                                        navItem.subMenu && 
                                        <>
                                            <span className="cursor-pointer group flex flex-row space-x-8 items-center" onClick={()=> setExpandedMenuIndex(itemIndex)} onMouseEnter={Rotate}>
                                                    {navItem.icon()}
                                                <span className="flex-1 font-clarikageo text-white text-xl font-semibold tracking-[13.2px] uppercase opacity-60 group-hover:opacity-100 text-left">
                                                    {navItem.name}
                                                </span>
                                                <span className='opacity-60 group-hover:opacity-100 mb-0.5 ml-4 cursor-pointer'>
                                                    <svg fill="#c1f7f5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z"/></svg>
                                                </span>
                                            </span>
                                        </>
                                    }
                                </div>)
                            )
                        }
                    </div>}

                    {
                        <div className={`absolute inset-0 transition-all duration-500 ${expandedMenuIndex >= 0 ? 'opacity-100 translate-x-0 pointer-events-auto' : 'opacity-0 translate-x-full pointer-events-none'} flex flex-col space-y-6 lg:space-y-9 transition-all duration-500`}>
                            <div className='flex gap-4 mb-4 opacity-60 hover:opacity-100 w-fit justify-start cursor-pointer' onClick={()=> setExpandedMenuIndex(-1)}>
                                <svg className='rotate-180 mt-0.5' fill="#c1f7f5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z"/></svg>
                                <span className='flex-1 font-clarikageo text-white text-xl font-semibold tracking-[13.2px] uppercase opacity-60 group-hover:opacity-100 text-left'>BACK</span>
                            </div>
                           {((navItems[expandedMenuIndex] && navItems[expandedMenuIndex].subMenu) || []).map((navItem, itemIndex) => (
                                <div key={itemIndex} className='flex gap-1 align-middle items-center'>
                                    <Link className="group flex flex-row space-x-8 items-center" to={navItem.url} onClick={()=> { setExpandedMenuIndex(-1); callback(); }} onMouseEnter={Rotate}>
                                            {navItem.icon && navItem.icon()}
                                        <span className="flex-1 font-clarikageo text-white text-xl font-semibold tracking-[13.2px] uppercase opacity-60 group-hover:opacity-100 text-left">
                                            {navItem.name}
                                        </span>
                                    </Link>
                                </div>)
                            )}
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}