import React, { useEffect, useRef } from "react";
// import { Link } from "react-router-dom"; // Removed unused import
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import Lottie from "lottie-react";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up.json";
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down.json";
// import { EnvelopeIcon } from "@heroicons/react/20/solid"; // Removed unused import

// import data from "../copy/data"; // Removed unused import

export default function ContactPage({
  callback,
  callback2,
  copyright,
  metaTitle,
  metaDescription,
  metaKeywords,
}) {
  const hexagonDown = useRef();
  const hexagonUp = useRef();

  useEffect(() => {
    callback(false);
    callback2(false);
    hexagonDown.current.playSegments([0, 50], true);
    hexagonUp.current.playSegments([0, 50], true);
  }, [callback, callback2]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title data-rh="true">
          {metaTitle || "Contact | Geometry"}
        </title>
        <meta
          name="description"
          content={truncateString(
            metaDescription
              ? metaDescription
              : "High impact deep tech investing",
            152,
          )}
          data-rh="true"
        />
        <meta
          name="keywords"
          content={
            metaKeywords
              ? metaKeywords
              : "investing, deep tech, applied cryptography, zero knowledge proofs, semiconductor, materials science, computer technologies, photonics"
          }
          data-rh="true"
        />
      </Helmet>
      <div className="fixed left-0 top-0 z-0 h-screen w-screen">
        <Lottie
          lottieRef={hexagonDown}
          className="absolute scale-50 lg:scale-100"
          style={{ left: "-300px", width: "600px", top: "-200px" }}
          animationData={hexagonDownAnimation}
          autoplay={false}
          loop={false}
        />
        <Lottie
          lottieRef={hexagonUp}
          className="absolute scale-50 lg:scale-100"
          style={{ right: "-300px", width: "600px", bottom: "-300px" }}
          animationData={hexagonUpAnimation}
          autoplay={false}
          loop={false}
        />
      </div>
      <div
        id="contact-page-container"
        className="fade-in absolute flex h-full w-full flex-col"
      >
        <div className="z-20 flex grow flex-col items-center justify-around">
          <div className="font-clarikageo mt-0 justify-center text-center text-2xl text-white" />
          <div className="font-clarikageo mt-0 justify-center text-center text-2xl text-white" />
          <div className="font-clarikageo mt-0 justify-center text-center text-2xl text-white">
            We love hearing from ambitious technologists with an impactful breakthrough to share with the world. There's no "too early."
          </div>
          <div className="font-clarikageo mt-0 justify-center text-center text-2xl text-white">
            You can get in touch by{" "}
            <a
              href="mailto:ben@geometrytwo.com,tom@geometrytwo.com,myles@geometrytwo.com"
              className="font-bold underline hover:opacity-80"
            >
              emailing us
            </a>{" "}
            or via X (
            <a
              href="https://x.com/tomwalpo"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline hover:opacity-80"
            >
              Tom
            </a>
            ,{" "}
            <a
              href="https://x.com/BenLevy0"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline hover:opacity-80"
            >
              Ben
            </a>
            ,{" "}
            <a
              href="https://x.com/MylesStapelberg"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline hover:opacity-80"
            >
              Myles
            </a>
            ).
          </div>
          <div className="font-clarikageo mt-0 justify-center text-center text-2xl text-white" />
          <div className="font-clarikageo mt-0 justify-center text-center text-2xl text-white" />
        </div>
        <div className="z-20 grow-0">
          <p className="font-clarikageo select-none pb-16 text-center text-sm text-white lg:text-base">
            {copyright}
          </p>
        </div>
      </div>
    </>
  );
}
