// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
// Lottie
import Lottie from "lottie-react";
// Lottie animations
import linesAnimation from "../animations/home-page-lines-layer.json";
import hexagonAnimation from "../animations/home-page.json";
import logoAnimation from "../animations/geometry-logo-animation.json";

const easeInOutCubic = function (x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
};

export default function Home({
  callback,
  callback2,
  animationDuration,
  metaTitle,
  metaDescription,
  metaKeywords,
}) {
  if (!animationDuration) animationDuration = 1750;

  const container = useRef();
  const lines = useRef();
  const hexagon = useRef();
  const logo = useRef();
  const enterButton = useRef();

  const navigate = useNavigate();
  let animation;

  useEffect(() => {
    callback(false);
    callback2(true);
  }, [callback, callback2]);

  const progessAnimation = function (endTime) {
    const progress = easeInOutCubic(
      Math.min(1, 1 - (endTime - Date.now()) / animationDuration),
    );

    if (progress >= 1) {
      clearInterval(animation);
      callback2(false);
      navigate("/about");
    }

    const scale = 1 + 3 * progress;
    const opacity = (1 - progress) * 100;

    // lines
    lines.current.animationContainerRef.current.style.transform = `scale(${scale})`;
    lines.current.animationContainerRef.current.style.opacity = `${opacity}%`;

    // Hexagon
    hexagon.current.animationContainerRef.current.style.transform = `scale(${scale})`;
    hexagon.current.animationContainerRef.current.style.opacity = `${opacity}%`;

    // Logo
    const logoProgress = Math.max(progress - 0.25, 0);
    const logoAnimationDuaration = logo.current.getDuration(true);
    logo.current.goToAndStop(
      Math.round(logoAnimationDuaration * logoProgress),
      true,
    );

    var logoWidth = logo.current.animationContainerRef.current.offsetWidth;
    var newProgress = Math.max((progress - 0.5) * 2, 0);

    if (document.documentElement.clientWidth < 1024)
      logo.current.animationContainerRef.current.style.transform = `translateX(${
        (logoWidth / 2 - container.current.offsetWidth / 2) * newProgress
      }px) translateY(${
        (logoWidth / 2 - container.current.offsetHeight / 2) * newProgress
      }px)`;
    else
      logo.current.animationContainerRef.current.style.transform = `translateX(${
        (logoWidth / 2 - container.current.offsetWidth / 2) * newProgress
      }px)`;
  };

  const startAnimation = function () {
    enterButton.current.disabled = true;
    enterButton.current.classList.add("fade-out");
    const endTime = Date.now() + animationDuration;
    animation = setInterval(() => progessAnimation(endTime), 10);
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title data-rh="true">
          {metaTitle || "Geometry"}
        </title>
        <meta
          name="description"
          content={truncateString(
            metaDescription
              ? metaDescription
              : "High impact deep tech investing",
            152,
          )}
          data-rh="true"
        />
        <meta
          name="keywords"
          content={
            metaKeywords
              ? metaKeywords
              : "investing, deep tech, applied cryptography, zero knowledge proofs, semiconductor, materials science, computer technologies, photonics"
          }
          data-rh="true"
        />
      </Helmet>

      <div
        ref={container}
        className="fade-in relative h-full w-full overflow-visible"
      >
        <div className="absolute z-0 flex h-full w-full items-center justify-center lg:-top-20">
          <Lottie
            lottieRef={lines}
            className="h-full w-auto"
            animationData={linesAnimation}
            autoplay={true}
            loop={true}
          />
        </div>
        <div className="absolute z-10 flex h-full w-full items-center justify-center lg:-top-20">
          <Lottie
            lottieRef={hexagon}
            className="h-4/6 w-auto"
            animationData={hexagonAnimation}
            autoplay={true}
            loop={true}
          />
        </div>
        <div className="absolute z-20 flex h-full w-full items-center justify-center lg:-top-20">
          <Lottie
            lottieRef={logo}
            className="h-20 w-20 lg:h-32 lg:w-32 2xl:h-40 2xl:w-40"
            animationData={logoAnimation}
            autoplay={false}
            loop={false}
          />
        </div>
        <div className="absolute bottom-0 left-0 z-30 flex h-auto w-full justify-center">
          <button
            ref={enterButton}
            className="group mb-4 flex-col items-center"
            onClick={startAnimation}
          >
            <p className="font-clarikageo group-hover:text-cyan mb-5 text-xl font-semibold uppercase tracking-[13.2px] text-white transition-colors duration-500">
              Enter
            </p>
            <svg
              className="text-cyan mx-auto h-7 w-7 animate-bounce"
              fill="currentColor"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}
