import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout.js";
import Home from "./components/Home";
import About from "./components/About";
import ContactPage from "./pages/ContactPage.js";
import { FullScreenProvider } from "./components/FullScreenProvider";
import WritingPage from "./pages/WritingPage.js";

import Data from "./copy/data";

// import ingonyamaBackground from "./assets/companies/backgrounds/ingonyama.png";
// import scrollBackground from "./assets/companies/backgrounds/scroll.png";
// import risczeroBackground from "./assets/companies/backgrounds/risczero.png";
// import stealthcoBackground from "./assets/companies/backgrounds/stealthco.png";

// open a new analytics account: https://analytics.google.com/analytics/web/
// ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);

export default function App() {
  // should be using context for data but there's little of it
  // const [data, setData] = useState(Data);
  const [light, setLight] = useState(false);
  const [landing, setLanding] = useState(false);

  // const imagesToPreload = [
  //   ingonyamaBackground,
  //   scrollBackground,
  //   risczeroBackground,
  //   stealthcoBackground,
  // ];

  // const people = Data.people;

  // for (const person of people) {
  //   imagesToPreload.push(person.imageURL);
  // }

  // function preloadImages(array) {
  //   if (!preloadImages.list) {
  //     preloadImages.list = [];
  //   }
  //   var list = preloadImages.list;
  //   for (var i = 0; i < array.length; i++) {
  //     var img = new Image();
  //     img.onload = function () {
  //       var index = list.indexOf(this);
  //       if (index !== -1) {
  //         // remove image from the array once it's loaded
  //         // for memory consumption reasons
  //         list.splice(index, 1);
  //       }
  //     };
  //     list.push(img);
  //     img.src = array[i];
  //   }
  // }

  // preloadImages(imagesToPreload);

  return (
    <FullScreenProvider>
      <BrowserRouter>
        <Layout callback={setLight} light={light} landing={landing} Data={Data}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  callback={setLight}
                  callback2={setLanding}
                  metaTitle="Geometry"
                  metaDescription={Data && Data.home.metaDescription}
                  metaKeywords={Data && Data.home.metaKeywords}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About
                  callback={setLight}
                  callback2={setLanding}
                  textOne={Data && Data.about.textOne}
                  textTwo={Data && Data.about.textTwo}
                  metaTitle="Geometry"
                  metaDescription={Data && Data.about.metaDescription}
                  metaKeywords={Data && Data.about.metaKeywords}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <ContactPage
                  callback={setLight}
                  callback2={setLanding}
                  metaTitle="Contact | Geometry"
                  metaDescription={Data && Data.about.metaDescription}
                  metaKeywords={Data && Data.about.metaKeywords}
                />
              }
            />
            <Route
              path="/writing"
              element={
                <WritingPage
                  callback={setLight}
                  callback2={setLanding}
                  metaTitle="Writing | Geometry"
                  metaDescription={Data && Data.writing?.metaDescription}
                  metaKeywords={Data && Data.writing?.metaKeywords}
                />
              }
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    </FullScreenProvider>
  );
}
