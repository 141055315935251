import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import Lottie from "lottie-react";
import logoAnimation from "../animations/geometry-logo-animation.json";
import textAnimation from "../animations/geometry-text-in.json";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up.json";
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down.json";

export default function About({
  callback,
  callback2,
  textOne,
  textTwo,
  metaTitle,
  metaDescription,
  metaKeywords,
}) {
  const logo = useRef();
  const text = useRef();

  const hexagonDown = useRef();
  const hexagonUp = useRef();

  useEffect(() => {
    callback(false);
    callback2(false);
    logo.current.goToAndStop(100, true);
    text.current.setDirection(-1);
    text.current.playSegments([50, 1], true);
    hexagonDown.current.playSegments([0, 50], true);
    hexagonUp.current.playSegments([0, 50], true);
  }, [callback, callback2]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title data-rh="true">
          {metaTitle || "Geometry"}
        </title>
        <meta
          name="description"
          content={truncateString(
            metaDescription
              ? metaDescription
              : "High impact deep tech investing",
            152,
          )}
          data-rh="true"
        />
        <meta
          name="keywords"
          content={
            metaKeywords
              ? metaKeywords
              : "investing, deep tech, applied cryptography, zero knowledge proofs, semiconductor, materials science, computer technologies, photonics"
          }
          data-rh="true"
        />
      </Helmet>
      <div className="relative h-full w-full overflow-hidden">
        <div className="fixed left-0 top-0 z-0 h-screen w-screen">
          <Lottie
            lottieRef={hexagonDown}
            className="absolute scale-50 lg:scale-100"
            style={{ left: "-300px", width: "600px", top: "-200px" }}
            animationData={hexagonDownAnimation}
            autoplay={false}
            loop={false}
          />
          <Lottie
            lottieRef={hexagonUp}
            className="absolute scale-50 lg:scale-100"
            style={{ right: "-300px", width: "600px", bottom: "-300px" }}
            animationData={hexagonUpAnimation}
            autoplay={false}
            loop={false}
          />
        </div>
        <div className="no-scroll absolute z-10 block h-full w-full flex-row items-center overflow-y-auto lg:-top-20 lg:flex">
          <div className="hidden lg:flex mb-3 flex w-full flex-row flex-nowrap items-center pr-0 lg:mb-0 lg:w-auto lg:pr-20">
            <Lottie
              lottieRef={logo}
              className="mr-4 h-20 w-20 flex-none lg:h-32 lg:w-32 2xl:h-40 2xl:w-40"
              animationData={logoAnimation}
              autoplay={false}
              loop={false}
            />
            <Lottie
              lottieRef={text}
              className="h-full w-48 lg:w-60 2xl:w-72"
              animationData={textAnimation}
              autoplay={false}
              loop={false}
            />
          </div>
          <div className="border-cyan fade-in z-20 w-full flex-1 grow border-0 pb-6 pl-0 lg:w-auto lg:border-l lg:pb-0 lg:pl-16 lg:pr-10">
            <p className="font-clarikageo mb-8 text-xl font-medium text-white lg:mb-8 xl:text-2xl">
              {textOne || ""}
            </p>
            <p 
              className="font-clarikageo mb-8 text-lg font-light text-white lg:mb-8 xl:text-xl [&>a]:font-bold [&>a]:text-white [&>a]:underline [&>a:hover]:opacity-80"
              dangerouslySetInnerHTML={{ __html: textTwo || "" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
